.main_top{
    padding-left:2rem;
    display:flex;
    flex-direction: row;
    padding-top: 4rem;
    height: fit-content;
    padding-bottom: 1.5rem;
}
.playlist_info{
    padding-top: 3rem;
}

.playlist_img{
    width: 15rem;
    height: 15rem;
    margin-right: 1rem;
}

.playlist_nombre{
    font-size: 5vw;
    color:white;
    margin-bottom: 1rem;
}

.playlist_desc{
    color: #BBBBBB;
    margin-bottom: 1rem;
}
.playlist_owner{
    display:flex;
    flex-direction: row;
}
.owner_name{
    font-weight: bold;
    color: white;
    font-size: 14px;
}
.number_tracks{
    color: white;
    padding-left: 0.5rem;
    font-size: 14px;
}