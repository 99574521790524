.main_sidebar{
    width:14.5rem;
    background-color: #000;
    color: #F8F9FA;
}
.main_sidebar .logoSidebar{
    width:8rem;
    padding-top:0.5rem;
    padding: 1.5rem;
    padding-bottom: 0rem;
}
.sidebarOption{
    padding-top:1rem;
    padding-bottom:1rem;
    flex: 0 1 30px;
    padding: 1.5rem;
}
.item{
    display:flex;
    flex-direction: row;
    padding: 0.5rem;
    color: #b3b3b3;
}

.icon{
    padding-right:0.8rem;
}
.theme{
    display:flex;
    align-items: center;
    text-overflow:ellipsis;
    white-space: nowrap;

}
hr{
    border:0;
    border-bottom: 1px solid #232323;
    padding-top:1rem;
}
.main_sidebar .list{
    max-height: calc(100vh - 22rem);
    overflow-y: hidden;
}
.main_sidebar .list::-webkit-scrollbar{
    width: 0.9rem;
}
.main_sidebar .list::-webkit-scrollbar-thumb{
    background-color: #2E2E2E;
}
.main_sidebar .list::-webkit-scrollbar-thumb:hover{
    background-color: #7b7b7b;
}
.main_sidebar .list:hover{
    overflow-y:scroll;
}
.itemplaylist{
    padding: 0.4rem;
    padding-left: 2rem;
    font-size: 14px;
}

