*{
  margin:0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.login {
  height: 100vh;
  background-image: url(https://data.whicdn.com/images/228745602/original.gif);
  background-size: cover;

}
.content{
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.imglogo{
    width: 25rem;
}
.loginbtn{
    background-color: white;
    color: black;
    border-radius: 2rem;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    opacity: 0.5;
}
.loginbtn:hover{
    opacity: 1;
}

.text{
    color: white;
    padding-top: 1rem;
    padding-bottom: 5rem;
    opacity: 0.5;
}
.main_sidebar{
    width:14.5rem;
    background-color: #000;
    color: #F8F9FA;
}
.main_sidebar .logoSidebar{
    width:8rem;
    padding-top:0.5rem;
    padding: 1.5rem;
    padding-bottom: 0rem;
}
.sidebarOption{
    padding-top:1rem;
    padding-bottom:1rem;
    flex: 0 1 30px;
    padding: 1.5rem;
}
.item{
    display:flex;
    flex-direction: row;
    padding: 0.5rem;
    color: #b3b3b3;
}

.icon{
    padding-right:0.8rem;
}
.theme{
    display:flex;
    align-items: center;
    text-overflow:ellipsis;
    white-space: nowrap;

}
hr{
    border:0;
    border-bottom: 1px solid #232323;
    padding-top:1rem;
}
.main_sidebar .list{
    max-height: calc(100vh - 22rem);
    overflow-y: hidden;
}
.main_sidebar .list::-webkit-scrollbar{
    width: 0.9rem;
}
.main_sidebar .list::-webkit-scrollbar-thumb{
    background-color: #2E2E2E;
}
.main_sidebar .list::-webkit-scrollbar-thumb:hover{
    background-color: #7b7b7b;
}
.main_sidebar .list:hover{
    overflow-y:scroll;
}
.itemplaylist{
    padding: 0.4rem;
    padding-left: 2rem;
    font-size: 14px;
}


.main_body{
    width: 100%;
    display:flex;
    flex-direction: column;
}
.main_body .top{
    flex: 1 1 20px;
}

.main_body .bottom{
    flex: 1 1 auto;
}

.main_top{
    padding-left:2rem;
    display:flex;
    flex-direction: row;
    padding-top: 4rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 1.5rem;
}
.playlist_info{
    padding-top: 3rem;
}

.playlist_img{
    width: 15rem;
    height: 15rem;
    margin-right: 1rem;
}

.playlist_nombre{
    font-size: 5vw;
    color:white;
    margin-bottom: 1rem;
}

.playlist_desc{
    color: #BBBBBB;
    margin-bottom: 1rem;
}
.playlist_owner{
    display:flex;
    flex-direction: row;
}
.owner_name{
    font-weight: bold;
    color: white;
    font-size: 14px;
}
.number_tracks{
    color: white;
    padding-left: 0.5rem;
    font-size: 14px;
}
.main_bottom{
    display:flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    background-color: #00000034;
    color: #F8F9FA;
    max-height: calc(100vh - 30rem);
    overflow-y: auto;
}
.main_footer{
    position:absolute;
    bottom: 0;
    background-color: #181818;
    width: 100%;
    height: 6.5rem;
    color: white;
    border-top: 1px solid #282828;
}
.main{
    display:flex;
    flex-direction:column;
    
}
.main_{
    display:flex;
    flex-direction:row;
    background-color: rgb(18,18,18);    ;
    background: linear-gradient(0deg, rgba(18,18,18,1) 35%, rgb(114, 153, 204) 100%);
}

