.main_body{
    width: 100%;
    display:flex;
    flex-direction: column;
}
.main_body .top{
    flex: 1 1 20px;
}

.main_body .bottom{
    flex: 1 1 auto;
}
