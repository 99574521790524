.login {
  height: 100vh;
  background-image: url(https://data.whicdn.com/images/228745602/original.gif);
  background-size: cover;

}
.content{
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.imglogo{
    width: 25rem;
}
.loginbtn{
    background-color: white;
    color: black;
    border-radius: 2rem;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    opacity: 0.5;
}
.loginbtn:hover{
    opacity: 1;
}

.text{
    color: white;
    padding-top: 1rem;
    padding-bottom: 5rem;
    opacity: 0.5;
}